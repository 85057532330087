<!--
 * @Author: Neko
 * @Date: 2021-01-04 10:21:32
 * @LastEditTime: 2021-03-22 11:40:10
 * @LastEditors: Neko
-->
<template>
  <div class="layout__container">
    <header class="layout__header">
      <Header />
    </header>

    <main class="layout__main">
      <keep-alive include="Home">
        <router-view />
      </keep-alive>
    </main>

    <footer class="layout__footer">
      <Footer />
    </footer>
  </div>
</template>

<script>
import Header from './Header.vue'
import Footer from './Footer.vue'

export default {
  name: 'Layout',

  components: {
    Header,
    Footer
  }
}
</script>

<style lang="scss" scoped>
.layout__container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  overflow: hidden;
  background-color: #F8F9FA;
}

.layout__header {
  position: relative;
  // margin-bottom: -80px;
  z-index: 1;
}

.layout__main {
  min-height: calc(100vh - 127px - 120px);
  // padding: 80px 0 80px;
  // 此处不能加固定宽度，因为首页有部分内容是通屏宽度
  // width: 1200px;
  // margin: 0 auto;
}

.layout__footer {
  position: relative;
  // height: 80px;
  // margin-top: -80px;
  z-index: 1;
}
</style>
