/*
 * @Author: Neko
 * @Date: 2021-03-25 16:19:52
 * @LastEditTime: 2021-04-02 09:54:05
 * @LastEditors: Neko
 */
import Vue from 'vue'
import locale from 'element-ui/lib/locale'
import VueI18n from 'vue-i18n'

import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'en',
  messages: {
    'zh-CN': Object.assign(require('./local/zh-CN.js'), zhLocale),
    'en': Object.assign(require('./local/en.js'), enLocale)
  },

  silentTranslationWarn: true
})

locale.i18n((key, value) => i18n.t(key, value))

export default i18n
