/*
 * @Author: Neko
 * @Date: 2021-01-16 14:36:30
 * @LastEditTime: 2021-01-16 14:38:27
 * @LastEditors: Neko
 */
export default {
  path: '/group',
  name: 'GroupView',
  redirect: { name: 'GroupList' },
  component: () => import('@/view/group/view.vue'),
  children: [
    {
      path: 'list',
      name: 'GroupList',
      component: () => import('@/view/group/index.vue')
    }
  ]
}
