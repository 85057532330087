/*
 * @Author: Neko
 * @Date: 2021-01-13 13:07:01
 * @LastEditTime: 2021-03-25 14:42:44
 * @LastEditors: Neko
 */
export default {
  getGoodsList: ['GET', '/goods/list'],

  getShopData: ['GET', '/store/getByDomainPrefix'],

  getSadminCountryList: ['GET', '/countryCurrency/all/country/list'],
  getSadminCurrencyList: ['GET', '/countryCurrency/all/currency/list']
}
