/*
 * @Author: Neko
 * @Date: 2021-01-14 15:10:04
 * @LastEditTime: 2021-10-11 17:52:33
 * @LastEditors: Please set LastEditors
 */
export default {
  getOrderPreview: ['POST', '/cart/mobile/settlementPre'],
  handlePayAction: ['POST', '/cart/mobile/pay'],
  handleOnlinePayAction: ['GET', '/YSPay/pc/alipay/pay'],
  handleAlipayAuthAction: ['GET', '/YSPay/pc/alipayAuth'],
  handlePacyPayAction: ['GET', '/pacyPay/getPayInfo'],
  getWechatOpenId: ['GET', '/wx/auth'],
  handleWechatPayAction: ['GET', '/YSPay/pay'],
  handlePaymentSuccessAction: ['GET', '/order/loop/isPaySuccessful'],
  handleSubmitOrderAction: ['POST', '/cart/mobile/settlement'],
  orderNum: ['GET', '/order/typeNum'], // 订单数量
  orderList: ['GET', '/order/orderList'], // 订单列表

  orderDetail: ['GET', '/order/toBePay/detail'], // 订单详情
  orderCancel: ['POST', '/order/applyCancelOrder'], // 订单取消
  orderFinish: ['POST', '/order/finishReceiving'], // 订单收货
  getOrderPayStatusAction: ['GET', '/goodiesPay/getOrderIsPaySuccess'],
  getPayPalPayInfo: ['GET', '/payPal/getPayInfo'], // 获取payPal支付数据
  getCheckoutPayInfo: ['GET', '/checkout/getPayInfo'] // 获取payPal支付数据
}
