/*
 * @Author: Neko
 * @Date: 2021-01-12 17:15:39
 * @LastEditTime: 2021-10-13 19:28:25
 * @LastEditors: Please set LastEditors
 */

import router from '@/router'
import Vue from 'vue'

// 因设计不周，导致需要截取 linkUrl 中的 query 字符串
const getQueryParams = url => {
  const [, query] = url.split('?')

  if (query) {
    const queryArr = query.split('&')

    return queryArr.reduce((prev, current) => {
      const [key, value] = current.split('=')
      prev[key] = value
      return prev
    }, {})
  }
}

/**
 * 保留小数位数操作
 * @param {string | number} number 需要操作的数字
 * @param {2} float 需要做的保留小数
 */
export const convertNumberFloat = (number, float = 2) => {
  return (+number).toFixed(float)
}

export function validateMobile(_, value, callback) {
  if (!value) {
    callback(new Error(Vue.prototype.$t('utils.mobile_required')))
  }

  if (value.length < 11) {
    callback(new Error(Vue.prototype.$t('utils.mobile_format_error')))
  }

  callback()
}

export const handleLineClick = (row) => {
  if (row.modelType === 11) {
    router.push({
      name: 'GoodsAllView',
      query: {
        domain_prefix: row.domainPrefix
      }
    })
  }

  if (!row.linkUrl) return

  if (row.linkUrl === 'SORT_DYNAMIC') {
    router.push({
      name: 'GoodsAllView',
      query: {
        model_id: row.modelId,
        component_id: row.id,
        model_type: row.modelType
      }
    })
    return
  }

  if (row.linkUrl.slice(0, 4) === 'http') {
    window.open(row.linkUrl, '__blank')
    return
  }

  // 后台装修页面设计不周，导致需要使用这样的判断
  // 判断链接是否为商品详情页面
  if (row.linkUrl.indexOf('GOODS_DETAIL') > -1) {
    const query = getQueryParams(row.linkUrl)
    const resolvedLink = router.resolve({
      name: 'GoodsDetail',
      query
    })

    window.open(resolvedLink.href, '_blank')

    return
  }

  // 跳转其他的店铺页
  if (row.linkUrl.indexOf('SHOP_DETAIL') > -1) {
    const { domainPrefix } = getQueryParams(row.linkUrl)
    window.open(domainPrefix + '.' + process.env.VUE_APP_HOST_URL)

    return
  }

  if (row.linkUrl.indexOf('TOPIC_PAGE') > -1) {
    const { id } = getQueryParams(row.linkUrl)
    const resolvedLink = router.resolve({ name: 'Home', query: { id }})

    window.open(resolvedLink.href, '_blank')
    return
  }

  if (row.linkUrl.indexOf('GOODS_CATEGORY') > -1) {
    const { id_list } = getQueryParams(row.linkUrl)
    router.push({ name: 'GoodsAllList', query: { id_list: id_list }})
  }

  if (row.linkUrl.indexOf('GOODS_GROUP') > -1) {
    const { category_id } = getQueryParams(row.linkUrl)
    router.push({ name: 'GoodsAllList', query: { category_id }})
  }
}

export const getDomainPrefix = (() => {
  if (process.env.NODE_ENV === 'development') {
    if (process.env.VUE_APP_SHOP_DOMAIN === 'www') {
      return ''
    }
    return process.env.VUE_APP_SHOP_DOMAIN
  } else {
    const hostname = window.location.host
    const domain = hostname.split('.')[0]

    if (hostname.indexOf('tomorni.com') > -1) {
      return 'jewelry'
    }

    if (domain === 'www') {
      return ''
    }

    if (domain === 'wharfon') {
      return ''
    }

    return domain
  }
})()

// 序列化对象
export function serializeObj(obj) {
  return Object.entries(obj).map(current => {
    if (!current[1]) {
      return false
    }
    return current[0] + '=' + encodeURIComponent((current[1] || ''))
  }).filter(current => current).join('&')
}
