/*
 * @Author: Neko
 * @Date: 2020-12-31 14:15:02
 * @LastEditTime: 2021-10-11 13:25:46
 * @LastEditors: Please set LastEditors
 * @Description: 将所有的 api 文件夹中的所有文件的导出都合成到一个对象中
 * @FilePath: /druglots-pcapp/src/api/index.js
 */

const files = require.context('./', true, /\.js$/)
const regExp = /\.\/\w+\/(\w+)\.js/
const apis = {}

// 可能比较合适的定义 api 文件的方式，通过后端的路径切分成多级
files.keys().forEach(current => {
  // 为了去除 api/index.js 的路径，在这里做了判断
  if (regExp.test(current)) {
    const name = current.replace(regExp, '$1')
    apis[name] = require(`${current}`).default
  }
})

export default apis
