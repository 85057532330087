module.exports = {
  account: {
    accountUser: 'Personal center/account settings',
    editEmail: 'Modify mailbox',
    isEmail: 'Current email address',
    newEmail: 'New email address',
    veriCode: 'Verification code',
    enterEdit: 'OK to modify',
    editUserName: 'Modify username',
    isUserName: 'Current username',
    newUserName: 'New username',
    userNamePlaceholder: 'Please enter new user name (within 20 characters)',
    editPwd: 'Modify password',
    emails: 'Email address',
    newPwd: 'New password',
    enterPwd: 'Confirm password',
    enterSave: 'OK to modify',
    input: 'Please enter',
    pwdErr: 'The password entered twice is not the same',
    emailErr: 'Please enter the correct email',
    editSuccess: 'Modify successfully'
  },
  address: {
    addres: 'Personal Center/My Address',
    setDefault: 'Set as default',
    defaultAddre: 'Default address',
    edit: 'Edit',
    delAddre: 'Delete address',
    choice: 'Please select',
    myUser: 'Personal Center',
    myAddres: 'My Address',
    addAdsSuccess: 'Add shipping address successfully',
    oneAddres: 'Keep at least one shipping address',
    enterDel: 'Sure to delete this shipping address?',
    delAdder: 'Delete shipping address',
    confirm: 'OK',
    cancel: 'Cancel'
  },
  announcement: {
    notice: 'Notice'
  },
  cart: {
    spCart: 'Cart',
    allDel: 'Delete all',
    priceInfo: 'Price info',
    orderTotalPrc: 'Total order amount',
    clickOrder: 'Go to checkout',
    operationSuccess: 'Operation successful',
    isDelGood: 'Delete current item or not',
    leastGood: 'Please select at least one item',
    whetherDel: 'Whether to delete the selected item',
    tips: 'Tips',
    isNumber: 'Please check the number of purchases',
    whetherDelColl: 'Do you want to delete this collection?'
  },
  common: {
    nullData: 'No data at the moment',
    inputTitle: 'Please enter a title',
    goods: 'Product',
    price: 'Unit price ($)',
    total: 'Quantity',
    pay: 'Payment',
    payTip: 'Please use WeChat client to scan the QR code above to pay',
    waiverPayment: 'Abandon payment',
    paySuccess: 'I have finished paying',
    cancellationPayment: 'Do you want to cancel the payment',
    payErr: "You haven't paid successfully",
    payResult: 'Payment result',
    seeOrder: 'View order',
    goHome: 'Go to home page',
    paySus: 'Payment successful',
    getPsySus: 'Checking payment result in progress',
    payFail: 'Payment failed',
    getCode: 'Get verification code',
    blur_info: 'Please enter',
    Editshippingaddress: 'Edit shipping address',
    addshippingaddress: 'Add shipping address',
    ConsigneeName: 'Consignee name',
    phonenumber: 'Mobile number',
    province: 'Province',
    city: 'City',
    area: 'District',
    Submit: 'Submit',
    Addreceivingaddresssuccessfully: 'Add shipping address successfully',
    modifiedaddressssuccessfully: 'Modify shipping address successfully',
    name: 'Name',
    phone: 'Mobile',
    address: 'Address',
    Setdefault: 'Set as default',
    preservation: 'Save',
    Pleaseinputaddresshosenumber:
      'Please enter the detailed delivery address, please be precise to the door number.',
    Defaultaddress: 'Default address',
    number: 'Quantity',
    stock: 'Stock',
    ShippingAddress: 'Shipping address',
    Shippers: 'Shipping Merchant',
    ShippingInformation: 'Shipping Information',
    Viewfreight: 'View Shipping Cost',
    Buynow: 'Buy Now',
    Addshoppingcart: 'Add to cart',
    go: 'Go',
    buy: 'Buy',
    Insufficientinventorygoods: 'Item is out of stock',
    Pleasebuyleastoneitem: 'Please buy at least one item',
    Addedsuccessfully: 'Add successful',
    Operationsuccessful: 'Operation successful',
    Deleteaddress: 'Do you want to delete this address?',
    Tips: 'Tips',
    policy: 'ABOUT US',
    buyAndaddCart: 'This product is not supported for purchase',
    goodsSoldOut: 'The item has been removed from shelves'
  },
  utils: {
    mobile_required: 'Please enter your cell phone number',
    mobile_format_error: 'The cell phone number is not in the correct format'
  },
  login: {
    member: 'Member',
    sign_in: 'Login',
    not_member: 'Non-member',
    sign_up: 'Register Now',
    register: 'Member Registration',
    email: 'Email',
    password: 'Password',
    forget_password: 'Forgot your password',
    agreement: 'Register means you agree',
    private: 'Privacy Policy',
    and: 'with',
    terms_of_use: 'Membership Regulations',
    register_successful: 'Registration successful',
    email_required: 'Please enter your email',
    password_required: 'Please enter your password',
    email_address: 'Email address',
    verify_code: 'Verification code',
    new_password: 'New password',
    repeat_password: 'Repeat password',
    submit: 'OK to change',
    return_to_login: 'Return to login',
    repeat_password_incorrect: 'The password entered twice is not the same',
    email_incorrect: 'Please enter the correct email',
    edit_successful: 'Modify successfully'
  },
  search: {
    search_histories: 'Search history',
    keyword_required: 'Please enter keywords'
  },
  me: {
    account_center: 'Personal Center',
    sign_out: 'Log out of account',
    delivery_address: 'My address',
    account_setting: 'Account Settings',
    order_number: 'Order Number',
    purchase_time: 'Purchase date',
    purchase_price: 'Purchase amount',
    purchase_status: 'Current Status',
    check_order: 'View Orders',
    all_orders: 'All Orders',
    to_be_pay: 'Pending Payment',
    to_be_delivery: 'To be shipped',
    delivering_1: 'Shipped',
    delivering: 'Pending Receipt',
    refund: 'After sale/refund',
    deal_success: 'Transaction completed',
    cancelled: 'Cancelled',
    cancelling: 'Cancellation in progress'
  },
  home: {
    store: {
      recommend_store: 'Recommended merchants',
      select_store: 'Please select a store',
      see_more: 'View more',
      title: 'Please enter title',
      content: 'Please enter policy content',

      terms_of_conditions: 'TERMS & CONDITIONS',
      private_policy: 'PRIVACY POLICY',
      delivery_policy: 'SHIPPING POLICY',
      refund_policy: 'RETURN & REFUND POLICY',
      about_us: 'ABOUT US',
      contact_us: 'CONTACT US',
      intellectual_property_rights: 'INTELLECTUAL PROPERTY RIGHTS',
      not_exists: 'Store Not Exists'
    },
    header: {
      select_country: 'Select country',
      country: 'Country',
      currency: 'Currency',
      home: 'Wharfon SEA',
      store_home: 'Store Home',
      all_goods: 'All Products',
      language: 'Language'
    }
  },
  Goodsall: {
    Allproducts: 'All products',
    homepage: 'Home',
    Moreproducts: 'More products'
  },
  goodsDetails: {
    Productdetails: 'Product Details',
    Productspecifications: 'Product specifications',
    basicinformation: 'Basic information'
  },
  order: {
    myOrder: 'My Order',
    PersonalCenter: 'Personal Center',
    currentstate: 'Current Status',
    Gopay: 'Check Out',
    ToPay: 'TO PAY',
    cancelorder: 'Cancel order',
    Confirmreceipt: 'Confirm receipt',
    number: 'Order number',
    time: 'Order time',
    Receivinginformation: 'Consignee information',
    consignee: 'Consignee',
    phonenumber: 'Mobile number',
    country: 'Country',
    Detailedaddress: 'Detailed address',
    Productinformation: 'Product information',
    Commodityamount: 'Product amount',
    Freightamount: 'Freight',
    OrderAmount: 'Total',
    Logisticsinformation: 'Shipment information',
    logisticscompany: 'Shipment Provider',
    LogisticsNo: 'Shipment Tracking Number',
    Nologisticsinformation: 'No Tracking information',
    Reasonforordercancellation: 'Reason for cancellation',
    Pleaseselect: 'Please select',
    Wrongorder: 'Wrong order',
    nowant: 'Not wanted',
    Outstock: 'Out of stock',
    Deliverytimetoolong: 'Delivery time too long',
    other: 'Other',
    remarks: 'Remarks',
    Pleaseentercomments: 'Please leave a message',
    determine: 'OK',
    cancel: 'Cancel',
    Allorders: 'All Orders',
    Orderdetails: 'Order details',
    Pleaseinput: 'Please enter',
    Tobepaid: 'Pending Payment',
    Tobedelivered: 'Pending Shipping',
    Tobereceived: 'Pending receipt',
    Completed: 'Completed',
    Cancelled: 'Cancelled',
    Refundcomplete: 'Refund complete',
    Applyingrefund: 'Refund in progress',
    Areyoureceivegoods: 'Are you sure to receive the goods',
    settlement: 'Order Settlement',
    Consigneeinformation: 'Consignee information',
    Changeaddress: 'Change address',
    Selectaddress: 'Select address',
    Paymentmethod: 'Payment method',
    priceinformation: 'Price information',
    Totalamountgoods: 'Total amount of goods',
    freight: 'Shipping cost',
    Totalorderamount: 'Total order amount',
    Payimmediately: 'Pay Now',
    Paymentfailed: 'Payment failed',
    Pleasereceivingaddress: 'Please add your shipping address first',
    paid: 'paid',
    unpaid: 'unpaid',
    postcode: 'postcode',
    stateRegion: 'State/Region',

    readPolicy: 'I have read and agreed to',
    termsOfUse: '《Terms & Conditions》',
    private_policy: '《Privacy Policy》',
    returnAndRefund: '《Return & Refund Policy》',
    notAgreedPolicy: 'Please agree the policies',
    payPalToPay: 'Use paypal to pay',
    checkoutToPay: 'Use checkout to pay'
  }
}
