/*
 * @Author: Neko
 * @Date: 2020-12-31 11:56:08
 * @LastEditTime: 2021-04-02 09:21:38
 * @LastEditors: Neko
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

import Layout from '@/components/Layout/Layout.vue'
import Search from './modules/Search.js'
import Announcement from './modules/Announcement.js'
import Profile from './modules/Profile.js'
import Login from './modules/Login.js'
import Order from './modules/Order.js'
import Goods from './modules/Goods.js'
import GoodsAll from './modules/GoodsAll.js'
import Address from './modules/Addess.js'
import Cart from './modules/Cart.js'
import Collection from './modules/Collection.js'
import Group from './modules/Group.js'
import Outter from './modules/Outter.js'
import Payment from './modules/Payment.js'
import Account from './modules/Account.js'
import Article from './modules/Article.js'
import Download from './modules/Download.js'
import session from '@/utils/session'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('@/view/home/index.vue')
      },

      Search,
      Announcement,
      Profile,
      Login,
      Order,
      Goods,
      GoodsAll,
      Address,
      Cart,
      Collection,
      Group,
      Payment,
      Account,
      Article,
      Download
    ]
  },
  Outter,
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  sessionStorage.redirect = from.fullPath

  if (to.meta.requireAuth) {
    const token = session.get('token')
    if (token && token.length > 0) {
      next()
    } else {
      next({ path: '/user/login' })
    }
  } else {
    next()
  }
})

export default router
