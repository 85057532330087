<!--
 * @Author: Neko
 * @Date: 2021-01-04 10:15:19
 * @LastEditTime: 2021-04-01 17:55:13
 * @LastEditors: Neko
-->
<template>
  <div
    class="header__container"
    :class="{
      backgroundWhite: activeTab !== 0
    }"
  >
    <div class="header__wrap">
      <div class="left">
        <router-link class="logo" :to="{ path: '/' }">
          <img :src="(headerData || {}).imageUrl">
        </router-link>

        <h1 v-if="!isMainShop" class="shop-name">{{ shopData.storeName }}</h1>
      </div>

      <div class="center">
        <ul class="tab-list">
          <li
            v-for="item in tabList"
            :key="item.value"
            class="item"
            :class="{ active: item.value === activeTab }"
            @click="onClickTabItem(item)"
          >
            <router-link :to="item.url">
              {{ item.name }}
            </router-link>
          </li>
        </ul>
      </div>

      <div class="right">
        <ul class="icon-list">
          <!-- <li class="item country">
            <el-popover :disabled="isDisabledCurrency" placement="bottom">
              <a slot="reference" class="link" href="javascript:void(0)">
                <div class="content">
                  <span>{{ countryItem.country || $t('home.header.select_country') }}</span>

                  <i class="arrow-down el-icon-caret-bottom" />
                </div>
              </a>

              <el-form>
                <el-form-item :label="$t('home.header.country') + '：'" prop="countryId">
                  <el-select :value="countryItem" value-key="id" @change="onSelectCountry">
                    <el-option
                      v-for="item in countryList"
                      :key="item.id + 'country'"
                      :label="item.country"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>

                <el-form-item :label="$t('home.header.currency') + '：'" prop="currencyId">
                  <el-select :value="currencyItem" value-key="id" @change="onSelectCurrency">
                    <el-option
                      v-for="item in currencyList"
                      :key="item.id + 'currency'"
                      :label="item.currency + '（' + item.currencyUnit + '）'"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>

                <el-form-item :label="$t('home.header.language')" prop="language">
                  <el-select :value="language" @change="onChangeLanguage">
                    <el-option
                      v-for="item in LANGUAGE_LIST"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-form>
            </el-popover>
          </li> -->

          <li class="item">
            <router-link class="link" to="/search">
              <div class="icon">
                <i class="el-icon-search" />
              </div>

              <!-- <p class="text">搜索</p> -->
            </router-link>
          </li>

          <li class="item">
            <router-link class="link" :to="{ name: 'Cart' }" target="_blank">
              <div class="icon">
                <i class="el-icon-shopping-cart-full" />
              </div>

              <!-- <p class="text">购物车</p> -->
            </router-link>
          </li>

          <li v-if="isShowAnnouncement" class="item">
            <router-link
              class="link"
              :to="{
                name: 'Announcement',
                query: { domain_prefix: domainPrefix }
              }"
            >
              <div class="icon">
                <i class="el-icon-bell" />
              </div>

              <!-- <p class="text">公告</p> -->
            </router-link>
          </li>

          <li class="item long">
            <router-link class="link" :to="{ name: 'Profile' }">
              <div class="icon">
                <i class="el-icon-user" />
              </div>

              <!-- <p class="text">个人中心</p> -->
            </router-link>
          </li>

          <li class="item">
            <router-link class="link" :to="{ name: 'DownloadIndex' }" target="_blank">
              <div class="icon">
                <i class="el-icon-download" />
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted, reactive, toRefs } from '@vue/composition-api'

import { getDomainPrefix } from '@/utils'
import { LANGUAGE_LIST } from '@/utils/constant'
import { useRequest } from '@/utils/request'

export default {
  name: 'Header',

  setup(_, { root }) {
    const store = root.$store
    const route = root.$route
    const $jst = root.$jst

    const shopData = computed(() => store.state.app.shopData)
    const headerData = computed(() => store.state.app.headerData)
    const homeTemplate = computed(() => store.state.app.template)

    const token = computed(() => store.state.user.token)

    const isMainShop = computed(() => getDomainPrefix === '')

    const activeTab = ref('')
    const isDisabledCurrency = ref(false)

    const tabList = computed(() => ([
      {
        name: getDomainPrefix === '' ? $jst('home.header.home') : $jst('home.header.store_home'),
        value: 0,
        url: '/'
      },
      {
        name: $jst('home.header.all_goods'),
        value: 1,
        url: '/goodsAll'
      }
    ]))

    const domainPrefix = computed(() => getDomainPrefix)

    watch(
      () => root.$route,
      (to) => {
        if (to.name === 'OrderSubmit') {
          isDisabledCurrency.value = true
        } else {
          isDisabledCurrency.value = false
        }
        if (to.name === 'Home') {
          activeTab.value = 0
          return
        }

        // 保证进入全部商品列表时，只有不带参数时，才会有 active 状态
        if (to.name === 'GoodsAllList' && !(to.fullPath.indexOf('?') > -1)) {
          activeTab.value = 1
          return
        }

        activeTab.value = ''
      }
    )

    const isShowAnnouncement = ref(false)

    // 获取首页框架
    const { data, fetch } = useRequest('home/getHomeData', {
      data: computed(() => ({
        type: getDomainPrefix === '' ? '1' : '2',
        domainPrefix: getDomainPrefix // process.env.VUE_APP_SHOP_DOMAIN
      })),

      immediate: false,
      isErrorMessage: false
    })

    const { data: shopDataResult } = useRequest('common/getShopData', {
      data: {
        domainPrefix: getDomainPrefix // process.env.VUE_APP_SHOP_DOMAIN
      },
      immediate: getDomainPrefix !== ''
    })

    watch(
      () => shopDataResult.value,
      (res) => {
        store.commit('app/SET_SHOP_DATA', res)
        store.commit('app/SET_REFRESH_MODEL_FUNC', fetch)
      }
    )

    const { data: getAdvertisementListResult, fetch: getAdvertisementList } = useRequest('home/getAdvertisementList', {
      immediate: false
    })

    watch(homeTemplate, _ => {
      if (!headerData.value) {
        store.dispatch('app/GET_HEADER_DATA', {
          fetch: getAdvertisementList,
          result: getAdvertisementListResult
        })
      }
    })

    watch(data, () => {
      store.commit('app/SET_HOME_TEMPLATE', data.value)
      store.dispatch('app/GET_HEADER_DATA')

      isShowAnnouncement.value = data.value.some(
        (current) => current.modelType === 4
      )
    })

    const countryList = reactive({
      currencyList: [],
      countryList: [],

      countryItem: computed(() => store.state.app.countryItem),
      currencyItem: computed(() => store.state.app.currencyItem),
      language: computed(() => store.state.app.language)
    })

    const onSelectCountry = item => {
      store.commit('app/SET_COUNTRY', item)

      if (getDomainPrefix === '') {
        getSadminCurrencyListFetch()
      }
    }

    const onSelectCurrency = item => {
      store.commit('app/SET_CURRENCY', item)
    }

    const { data: getCountryListResult, fetch: getCountryListFetch } = useRequest('goodsall/getCountryList', {
      data: computed(() => ({
        domainPrefix: getDomainPrefix
      })),

      immediate: false
    })

    const { fetch: getSadminCountryListFetch, data: getSadminCountryListResult } = useRequest('common/getSadminCountryList', {
      immediate: false
    })

    const { fetch: getSadminCurrencyListFetch, data: getSadminCurrencyListResult } = useRequest('common/getSadminCurrencyList', {
      data: computed(() => ({
        countryId: countryList.countryItem.id
      })),
      immediate: false
    })

    watch(getCountryListResult, res => {
      if ((!countryList.countryItem) && (!countryList.currencyItem)) {
        store.commit('app/SET_COUNTRY', res.countryList[0])
        store.commit('app/SET_CURRENCY', res.currencyList[0])
      }

      countryList.currencyList = res.currencyList
      countryList.countryList = res.countryList

      store.commit('app/SET_COUNTRY_LIST', res.countryList)
      store.commit('app/SET_CURRENCY_LIST', res.currencyList)
    })

    watch(getSadminCountryListResult, res => {
      if (!countryList.countryItem) {
        store.commit('app/SET_COUNTRY', res[0])
      }

      if (!res.some(current => current.id === countryList.countryItem.id)) {
        store.commit('app/SET_COUNTRY', res[0])
      }

      countryList.countryList = res

      store.commit('app/SET_COUNTRY_LIST', res)

      getSadminCurrencyListFetch()
    })

    watch(getSadminCurrencyListResult, res => {
      if (!countryList.currencyItem) {
        store.commit('app/SET_CURRENCY', res[0])
      }

      if (!res.some(current => current.id === countryList.currencyItem.id)) {
        store.commit('app/SET_CURRENCY', res[0])
      }

      countryList.currencyList = res

      store.commit('app/SET_CURRENCY_LIST', res)
    })

    const onClickTabItem = (item) => {
      activeTab.value = item.value
    }

    const onChangeLanguage = item => {
      store.commit('app/SET_LANGUAGE', item)
    }

    onMounted(() => {
      if (route.name === 'OrderSubmit') {
        isDisabledCurrency.value = true
      }

      if (route.name === 'Home') {
        if (!route.query.id) {
          fetch()
        }
      } else {
        fetch()
      }

      if (getDomainPrefix === '') {
        getSadminCountryListFetch()
      } else {
        getCountryListFetch()
      }

      if (route.name === 'Home') {
        activeTab.value = 0
        return
      }

      // 保证进入全部商品列表时，只有不带参数时，才会有 active 状态
      if (route.name === 'GoodsAllList' && !(route.fullPath.indexOf('?') > -1)) {
        activeTab.value = 1
        return
      }
    })

    return {
      ...toRefs(countryList),
      token,
      LANGUAGE_LIST,

      isShowAnnouncement,
      isMainShop,
      domainPrefix,
      shopData,
      headerData,
      isDisabledCurrency,

      tabList,
      activeTab,
      onClickTabItem,
      onSelectCountry,
      onSelectCurrency,
      onChangeLanguage
    }
  },

  methods: {}
}
</script>

<style lang="scss" scoped>
@mixin logo($width, $height) {
  width: $width;
  height: $height;
  text-align: center;

  img {
    max-width: $width;
    // width: 100%;
    height: 100%;
  }
}

.header__container {
  padding: 70px 0;
  background: #fff;
  &.backgroundWhite {

    background: transparent;
  }
  // margin-bottom: 50px;
  // background: #fff;
  // box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.08);
}

.header__wrap {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  max-width: 1200px;
  margin: 0 auto;
  // background: #fff;

  .left {
    display: flex;
    padding: 10px 0 7px;

    .logo {
      @include logo(120px, 60px);
    }

    .shop-name {
      align-self: flex-end;
      margin: 18px 12px;
      font-size: 16px;
      line-height: 24px;
      color: #222222;
      font-weight: normal;
    }
  }

  .center {
    padding: 0px 0 7px;
    align-self: center;

    .tab-list {
      padding: 0;
      margin: 10px 0 0 0;

      .item {
        display: inline-block;
        margin-right: 70px;

        a {
          position: relative;
          padding: 7px 12px;
          list-style: none;
          font-size: 14px;
          font-weight: 500;
          text-decoration: none;
          color: #222222;

          &::after {
            content: '';
            position: absolute;
            width: 0;
            height: 2px;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            background: #0049ac;
            transition: 0.3s all;
          }
        }

        &.active {
          a {
            color: #0049ac;

            &::after {
              content: '';
              width: 100%;
            }
          }
        }

        &:hover {
          a {
            color: #0049ac;
          }
        }
      }
    }
  }

  .right {
    padding: 16px 0 10px;

    .icon-list {
      padding: 0;
      margin: 0;

      .item {
        display: inline-block;
        margin-left: 30px;
        list-style: none;

        .link {
          display: block;
          text-decoration: none;
          color: #B4B5BB;

          &:hover {
            // color: #0049ac;
            color: #fff;

            .icon {
              background: #0049ac;
            }
          }
        }

        &.country {
          width: 130px;
          vertical-align: top;
          padding: 0 25px;
          border-radius: 25px;
          box-shadow: 0px 10px 16px 0px rgba(217, 217, 217, 0.24);
          background: #fff;

          .content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 50px;
            line-height: 50px;
            white-space: nowrap;

            span {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          span {
            width: 100%;
            display: inline-block;
            margin-right: 8px;
            color: #B4B5BB;
          }

          .arrow-down {
            display: inline-block;
            flex-shrink: 0;
            color: #B4B5BB;
          }
        }

        .icon {
          text-align: center;
          font-size: 20px;
          width: 50px;
          height: 50px;
          background: #ffffff;
          box-shadow: 0px 5px 8px 0px rgba(217, 217, 217, 0.24);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .icon:hover {
          box-shadow: 0px 5px 8px 0px rgba(195, 195, 195, 0);
        }

        .text {
          margin: 0;
          // line-height: 20px;
          text-align: center;
          font-size: 12px;
          // color: #555555;
        }

        &.long {
          margin-left: 23px;
        }
      }
    }
  }
}
</style>
