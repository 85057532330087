/*
 * @Author: Neko
 * @Date: 2020-12-31 11:56:08
 * @LastEditTime: 2021-10-16 15:39:08
 * @LastEditors: Please set LastEditors
 */
import apis from '@/api/index.js'
import $t from '@/language'
import router from '@/router'
import store from '@/store'
import session from '@/utils/session'
import { onMounted, reactive, toRefs } from '@vue/composition-api'
import axios from 'axios'
import { Loading, Message } from 'element-ui'
import { Base64 } from 'js-base64'
const baseUrl =
  process.env.VUE_APP_BASE_URL === 'http://apitest-mall.wharfon.com'
    ? window.location.origin
    : process.env.VUE_APP_BASE_URL
const service = axios.create({
  timeout: 60000,
  // baseURL: process.env.VUE_APP_BASE_URL
  baseURL: baseUrl
})

service.interceptors.request.use(
  async(config) => {
    const token = session.get('token')

    if (token) {
      config.headers['authorization'] = token
    }

    config.headers['Local-Language'] = store.state.app.language

    // 设置vuex baseUrl
    // store.commit('app/SET_BASE_URL', baseurl)
    // 动态处理baseUrl
    // if (store.state.app.baseUrl) {
    //   config.url = store.state.app.baseUrl + config.url
    // } else {
    //   config.url = getDomain() + config.url
    // }

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

/**
 *
 * @param {string | Ref<string>} url 请求的 url
 * @param {{
 *  initialData: any | <Ref>any,
 *  data: any,
 *  immediate: boolean,
 *  isCustom: boolean,
 *  onSuccess: () => void,
 *  onFail: () => void,
 *  onError: () => void,
 *  redirectQuery: Object
 * } extends AxiosRequestConfig } config
 * 其他的 axios 配置，包括返回中的 data 的初始值，以及需要作为数据传输的 data，
 * 以及是否立即在 useRequest 调用时进行一次接口请求。
 * onSuccess 和 onFail 的回调函数。
 *
 * redirectUrl 指定登录后跳转的页面
 * redirectQuery 登录后的所需要带的参数
 *
 * isCustom 是否将返回数据的操作过程交给用户
 *
 * 建议在 initialData 中传入 Ref<data>，这样不会丢失响应性。数据更变会自动体现到 initialData 绑定对象上。
 */
function useRequest(url, config) {
  // 传递参数时产生的心智成本
  const _url = url.value || url
  const _config = config || {}

  const {
    initialData, // 返回数据的初始值
    data = {}, // 参数，内部已经做了处理，兼容 params 传参与 body 传参
    immediate = true, // 是否立即执行
    isCustom = false, // 是否将返回的数据 data 解构出来
    onSuccess = function() {}, // 成功回调
    onFail, // 接口返回的code码 不为200 与400 时，的回调，已经发出了请求
    onError = function() {}, // 接口未发出的错误回调
    redirectQuery = {}, // 登录后所需要携带的参数
    isErrorMessage = true, // 是否展示错误的服务端提示，这个属性将会是过渡使用，因为可以通过 onFail 自定义提示。
    ...otherConfig
  } = _config

  const urlArray = _url.split('/')
  // ? 这里很关键 所有的路径，请求都需要到api文件里面加字段，才能调接口
  const [method, realUrl] = urlArray.reduce((prev, current) => {
    return prev[current]
  }, apis)

  const state = reactive({
    isLoading: false,
    data: initialData || {}
  })

  const fetch = async() => {
    const loading = Loading.service({
      lock: false,
      // text: '正在请求',
      spinner: 'el-icon-loading',
      background: 'rgba(255, 255, 255, 0.3)',
      customClass: 'custom-loading'
    })

    state.isLoading = true

    const isGetMethod = method.toLowerCase() === 'get'

    const toSendData = data?.value || data
    if (toSendData.constructor === Object && Object.prototype.hasOwnProperty.call(toSendData, 'domainPrefix')) {
      if (store.state.app.domain) {
        toSendData.domainPrefix = store.state.app.domain
      } else {
        const domain = await getDomain()
        store.commit('app/SET_DOMAIN', domain)
        toSendData.domainPrefix = domain
      }
    }
    return service
      .request({
        url: realUrl,
        method,
        params: isGetMethod && toSendData,
        data: !isGetMethod && toSendData,
        ...otherConfig
      })
      .then(response => {
        loading.close()

        getInToken(response.headers)
        const data = response.data

        const settingData = data => {
          if (state.data?.value) {
            state.data.value = data
          } else {
            state.data = data
          }
        }
        state.isLoading = false

        // 后端是否提供全部都是 Restful 接口（?）
        if (data.code === 200) {
          if (!isCustom) {
            settingData(data.data)
          } else {
            settingData(data)
          }
          onSuccess(data)
        } else if (data.code === 400) {
          store.dispatch('user/Logout')

          const result = Base64.encode(
            JSON.stringify({
              callback_name: _url,
              ...toSendData,
              ...redirectQuery
            })
          )

          router.replace({
            path: '/user/login',
            query: {
              redirect: result
            }
          })
        } else {
          if (isCustom) {
            settingData(data)
          }

          if (onFail) {
            onFail(data)
          } else {
            if (isErrorMessage) {
              Message.error(data.message)
            }
          }
        }
      })
      .catch(e => {
        loading.close()
        state.isLoading = false
        onError(e)
        Message.error(e.message)
        throw Error(e)
      })
  }

  onMounted(() => {
    if (immediate) {
      fetch()
    }
  })

  const getInToken = res => {
    if (res) {
      const token = res.authorization || res.Authorization
      if (token) {
        store.dispatch('user/SetToken', token)
      }
    }
  }
  async function getDomain() {
    try {
      const { data } = await axios.get(
        `${baseUrl}/microPage/getStoreByDomain`,
        {
          params: {
            domainPrefix:
                window.location.hostname === 'localhost'
                  ? 'liqun.ren'
                  : window.location.hostname
          }
        }
      )
      const domain = data.data.domainPrefix
      store.commit('app/SET_DOMAIN', domain)
      return new Promise((resolve, reject) => {
        resolve(domain)
      })
    } catch (error) {
      Message.error($t.t('home.store.not_exists'))
      return
    }
  }
  return {
    ...toRefs(state),
    fetch
  }
}

export { useRequest }

