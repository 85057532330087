<!--
 * @Author: Neko
 * @Date: 2021-01-04 10:29:21
 * @LastEditTime: 2021-04-16 14:57:52
 * @LastEditors: Neko
-->
<template>
  <div class="footer__container">
    <div class="footer__wrap">
      <ul class="special-link">
        <li v-for="(item, index) in specialLinks" :key="index">
          <router-link :to="{ name: 'ArticlePage', query: {id:item.id} }" target="_blank">{{ item.storePolicyJson[0].name }}</router-link>
        </li>
      </ul>

      <div class="special-picture">
        <img src="@/assets/images/links.png">
      </div>

      <div class="copyright">
        Copyright © 2021 {{ shopData.storeName }}. All rights reserved
      </div>

      <article class="footer-component__article">
        <p class="title">{{ (footerData || {}).title || $t('home.store.title') }}</p>

        <p class="content">{{ (footerData || {}).content || $t('home.store.content') }}</p>
      </article>
    </div>

  </div>
</template>

<script>
import { computed, defineComponent, watch } from '@vue/composition-api'
import { useRequest } from '@/utils/request.js'

export default defineComponent({
  setup(_, { root }) {
    const $jst = root.$jst
    const store = root.$store

    const footerData = computed(() => store.state.app.footerData)
    const shopData = computed(() => store.state.app.shopData)
    const homeTemplate = computed(() => store.state.app.template)

    const { data: getTitleListResult, fetch: getTitleList } = useRequest('home/getTitleList', {
      immediate: false
    })

    // item.storePolicyJson[0].name
    const {data:specialLinks} = useRequest('home/getPolicylist',{
      data:{
        code:'en-US',
        domainPrefix:'',
      }
    })

    const specialLinkss = computed(() => ([
      {
        name: $jst('home.store.about_us'),
        url: 'ArticlePage',
        query: { name: 'ABOUT_US' }
      },
      {
        name: $jst('home.store.terms_of_conditions'),
        url: 'ArticlePage',
        query: { name: 'TERMS_OF_CONDITIONS' }
      },
      {
        name: $jst('home.store.delivery_policy'),
        url: 'ArticlePage',
        query: { name: 'SHIPPING_POLICY' }
      },

      {
        name: $jst('home.store.contact_us'),
        url: 'ArticlePage',
        query: { name: 'CONTACT_US' }
      },

      {
        name: $jst('home.store.refund_policy'),
        url: 'ArticlePage',
        query: { name: 'RETURN_REFUND' }
      },

      {
        name: $jst('home.store.private_policy'),
        url: 'ArticlePage',
        query: { name: 'PRIVACY_POLICY' }
      },

      {
        name: $jst('home.store.intellectual_property_rights'),
        url: 'ArticlePage',
        query: { name: 'INTELLECTUAL_PROPERTY_RIGHTS' }
      }
    ]))

    watch(homeTemplate, _ => {
      if (!footerData.value) {
        store.dispatch('app/GET_FOOTER_DATA', {
          fetch: getTitleList,
          result: getTitleListResult
        })
      }
    })

    return {
      footerData,
      shopData,
      specialLinks
    }
  }
})
</script>

<style lang="scss" scoped>
.footer__container {
  width: 100%;
  margin-top: 40px;
  min-height: 80px;
  // padding: 33px 0 30px;
  background-color: #fff;

  .footer__wrap {
    width: 1200px;
    padding: 33px 0 30px;
    margin: 0 auto;

    .special-link {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 900px;
      margin: 0 auto 20px;

      li {
        width: 25%;
        margin-bottom: 10px;
        text-align: center;
        font-size: 12px;
        flex-shrink: 0;

        a {
          display: inline-block;
          border-bottom: 1px solid #CAD0E8;
          color: #4981e8;
        }
      }
    }

    .special-picture {
      height: 20px;
      width: 900px;
      margin: 20px auto;
      text-align: center;

      img {
        height: 100%;
      }
    }

    .copyright {
      margin-bottom: 16px;
      font-size: 14px;
      text-align: center;
      color: #0049AC;
    }
  }

  .footer-component__article {
    .title {
      padding: 0 0 9px;
      margin: 0;
      font-size: 14px;
      text-align: center;
      color: #222222;
    }

    .content {
      width: 910px;
      margin: 0 auto;
      font-size: 12px;
      text-align: center;
      color: #999999;
    }
  }
}
</style>
