export default {
  path: '/outter',
  name: 'Outter',
  component: () => import('@/view/outter/view.vue'),
  children: [{
    path: 'pay',
    name: 'OutterPay',
    component: () => import('@/view/outter/pay.vue')
  }]
}
