/*
 * @Author: Neko
 * @Date: 2021-01-14 16:23:06
 * @LastEditTime: 2021-01-15 09:37:50
 * @LastEditors: Neko
 */
export default {
  getCartList: ['GET', '/cart/list/all'],

  handleUpdateCartAction: ['POST', '/cart/update'],
  handleDeleteCartAction: ['POST', '/cart/delete']
}
