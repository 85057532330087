/*
 * @Author: Neko
 * @Date: 2021-01-04 09:52:23
 * @LastEditTime: 2021-01-04 09:52:58
 * @Description: Vuex 的插件，保存到 localStorage
 * @LastEditors: Neko
 */
import session from '@/utils/session'

const localStoragePlugin = store => {
  store.subscribe((mutation, state) => {
    Object.keys(state).forEach((item, index) => {
      session.set(item, state[item])
    })
  })
}

export default [localStoragePlugin]
