/*
 * @Author: Neko
 * @Date: 2021-01-14 13:56:17
 * @LastEditTime: 2021-03-22 16:00:37
 * @LastEditors: Neko
 */
export default {
  addressAdd: ['POST', '/userAddress/add'], // 添加收货地址
  addressUpdate: ['POST', '/userAddress/update'], // 更新收货地址

  addressList: ['GET', '/userAddress/list'], // 收货地址列表
  setDefault: ['POST', '/userAddress/default'], // 设为默认收货地址
  addressDelete: ['POST', '/userAddress/delete'], // 删除收货地址
  addressDetail: ['GET', '/userAddress/detail'], // 收货地址详情

  getAddressList: ['GET', '/userAddress/list'],

  handleSetDefaultAction: ['POST', '/userAddress/default'],
  handleDeleteAddressAction: ['POST', '/userAddress/delete'],

  getAddressCountryList: ['GET', '/countryCurrency/getCountryList']
}
