
export default {
  path: '/download',
  name: 'Download',
  component: () => import('@/view/downView/view.vue'),
  children: [
    {
      path: 'index',
      name: 'DownloadIndex',
      component: () => import('@/view/downView/index.vue')
    }
  ]
}
