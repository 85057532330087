/*
 * @Author: Neko
 * @Date: 2021-03-20 13:52:05
 * @LastEditTime: 2021-03-20 13:53:20
 * @LastEditors: Neko
 */
export default {
  path: '/account',
  name: 'Account',
  redirect: { name: 'AccountPage' },
  component: () => import('@/view/account/view.vue'),
  meta: {
    name: '个人中心'
  },
  children: [
    {
      path: 'index',
      name: 'AccountPage',
      component: () => import('@/view/account/index.vue'),
      meta: {
        name: '账户设置',
        requireAuth: true
      }
    }
  ]
}
