/*
 * @Author: Neko
 * @Date: 2021-01-06 17:13:49
 * @LastEditTime: 2021-01-06 17:15:52
 * @LastEditors: Neko
 */
export default {
  path: '/announcement',
  name: 'Announcement',
  redirect: { name: 'AnnouncementList' },
  component: () => import('@/view/announcement/view.vue'),
  children: [{
    path: 'list',
    name: 'AnnouncementList',
    component: () => import('@/view/announcement/index')
  }]
}
