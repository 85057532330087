/*
 * @Author: Neko
 * @Date: 2021-03-25 18:42:32
 * @LastEditTime: 2021-03-25 19:56:24
 * @LastEditors: Neko
 */
export const LANGUAGE_LIST = [
  {
    name: '中文',
    value: 'zh-CN'
  },
  {
    name: 'English',
    value: 'en'
  }
]
