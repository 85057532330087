module.exports = {
  account: {
    accountUser: '个人中心/账户设置',
    editEmail: '修改邮箱',
    isEmail: '现邮箱地址：',
    newEmail: '新邮箱地址：',
    veriCode: '验证码：',
    enterEdit: '确定修改',
    editUserName: '修改用户名',
    isUserName: '现用户名：',
    newUserName: '新用户名：',
    userNamePlaceholder: '请输入新用户名（20个字符内）',
    editPwd: '修改密码',
    emails: '邮箱地址：',
    newPwd: '新密码：',
    enterPwd: '确认密码',
    enterSave: '确定修改',
    input: '请输入',
    pwdErr: '两次输入的密码不一致',
    emailErr: '请输入正确的邮箱',
    editSuccess: '修改成功'
  },
  address: {
    addres: '个人中心/我的地址',
    setDefault: '设为默认',
    defaultAddre: '默认地址',
    edit: '编辑',
    delAddre: '删除地址',
    choice: '请选择',
    myUser: '个人中心',
    myAddres: '我的地址',
    addAdsSuccess: '添加收货地址成功',
    oneAddres: '至少保留一个收货地址',
    enterDel: '确定删除此收货地址吗？',
    delAdder: '删除收货地址',
    confirm: '确定',
    cancel: '取消'
  },
  announcement: {
    notice: '店铺公告'
  },
  cart: {
    spCart: '购物车',
    allDel: '全部删除',
    priceInfo: '价格信息',
    orderTotalPrc: '订单总金额',
    clickOrder: '去结算',
    operationSuccess: '操作成功',
    isDelGood: '是否删除当前商品',
    leastGood: '请至少选择一个商品',
    whetherDel: '是否删除选中商品',
    tips: '提示',
    isNumber: '请检查购买个数',
    whetherDelColl: '是否删除该收藏？'
  },
  common: {
    nullData: '暂无数据',
    inputTitle: '请输入标题',
    goods: '商品',
    price: '单价（元）',
    total: '数量',
    pay: '支付',
    payTip: '请使用微信客户端扫描上方二维码进行支付',
    waiverPayment: '放弃支付',
    paySuccess: '我已支付完成',
    cancellationPayment: '是否取消支付',
    payErr: '您还未支付成功',
    payResult: '支付结果',
    seeOrder: '查看订单',
    goHome: '去首页',
    paySus: '支付成功',
    getPsySus: '查询支付结果中',
    payFail: '支付失败',
    getCode: '获取验证码',
    blur_info: '请输入',
    Editshippingaddress: '编辑收货地址',
    addshippingaddress: '添加收货地址',
    ConsigneeName: '收货人姓名',
    phonenumber: '手机号码',
    province: '省',
    city: '市',
    area: '区',
    Submit: '提交',
    Addreceivingaddresssuccessfully: '添加收货地址成功',
    'modifiedaddressssuccessfully:': '修改收货地址成功',
    name: '姓名',
    phone: '手机',
    address: '地址',
    Setdefault: '设为默认',
    preservation: '保存',
    Pleaseinputaddresshosenumber: '请输入详细的收货地址，请精确到门牌号。',
    Defaultaddress: '默认地址',
    number: '数量',
    stock: '库存',
    ShippingAddress: '发货地址',
    Shippers: '发货商家',
    ShippingInformation: '发货信息',
    Viewfreight: '查看运费',
    Buynow: '立即购买',
    Addshoppingcart: '添加购物车',
    go: '去',
    buy: '购买',
    Insufficientinventorygoods: '商品库存不足',
    Pleasebuyleastoneitem: '请至少购买一件商品',
    Addedsuccessfully: '添加成功',
    Operationsuccessful: '操作成功',
    Deleteaddress: '是否删除该地址？',
    Tips: '提示',
    policy: '政策',
    buyAndaddCart: '该商品暂不支持购买',
    goodsSoldOut: '该商品已经下架'
  },
  utils: {
    mobile_required: '请输入手机号码',
    mobile_format_error: '手机号码格式不正确'
  },
  login: {
    member: '会员',
    sign_in: '登录',
    not_member: '非会员',
    sign_up: '立即注册',
    register: '会员注册',
    email: '邮箱',
    password: '密码',
    forget_password: '忘记密码',
    agreement: '注册代表您已同意',
    private: '隐私政策',
    and: '与',
    terms_of_use: '会员条例',
    register_successful: '注册成功',
    email_required: '请输入邮箱',
    password_required: '请输入密码',
    email_address: '邮箱地址',
    verify_code: '验证码',
    new_password: '新密码',
    repeat_password: '重复密码',
    submit: '确定修改',
    return_to_login: '返回登录',
    repeat_password_incorrect: '两次输入的密码不一致',
    email_incorrect: '请输入正确的邮箱',
    edit_successful: '修改成功'
  },
  search: {
    search_histories: '搜索历史',
    keyword_required: '请输入关键字'
  },
  me: {
    account_center: '个人中心',
    sign_out: '退出账户',
    delivery_address: '我的地址',
    account_setting: '账户设置',
    order_number: '订单编号',
    purchase_time: '购买日期',
    purchase_price: '购买金额',
    purchase_status: '当前状态',
    check_order: '查看订单',
    all_orders: '全部订单',
    to_be_pay: '待付款',
    to_be_delivery: '待发货',
    delivering_1: '已发货',
    delivering: '待收货',
    refund: '售后/退款',
    deal_success: '交易完成',
    cancelled: '已取消',
    cancelling: '取消中'
  },
  home: {
    store: {
      recommend_store: '推荐商户',
      select_store: '请选择店铺',
      see_more: '查看更多',
      title: '请输入标题',
      content: '请输入政策内容',

      terms_of_conditions: '用户协议',
      private_policy: '隐私政策',
      delivery_policy: '运输政策',
      refund_policy: '退换货政策',
      about_us: '关于我们',
      contact_us: '联系我们',
      intellectual_property_rights: '知识产权条款',
      not_exists: '该店铺不存在'

    },

    header: {
      select_country: '选择国家',
      country: '国家',
      currency: '货币',
      home: 'Wharfon SEA',
      store_home: '店铺首页',
      all_goods: '全部商品',
      language: '语言'
    }
  },
  Goodsall: {
    Allproducts: '全部商品',
    homepage: '首页',
    Moreproducts: '更多商品'
  },
  goodsDetails: {
    Productdetails: '商品详情',
    Productspecifications: '商品规格',
    basicinformation: '基本信息'
  },
  order: {
    myOrder: '我的订单',
    PersonalCenter: '个人中心',
    currentstate: '当前状态',
    Gopay: '去付款',
    cancelorder: '取消订单',
    Confirmreceipt: '确认收货',
    number: '订单编号',
    time: '下单时间',
    Receivinginformation: '收货信息',
    consignee: '收货人',
    phonenumber: '手机号码',
    country: '国家',
    Detailedaddress: '详细地址',
    Productinformation: '商品信息',
    Commodityamount: '商品金额',
    Freightamount: '运费金额',
    OrderAmount: '订单总计',
    Logisticsinformation: '物流信息',
    logisticscompany: '物流公司',
    LogisticsNo: '物流单号',
    Nologisticsinformation: '暂无物流信息',
    Reasonforordercancellation: '取消订单原因',
    Pleaseselect: '请选择',
    Wrongorder: '下错单',
    nowant: '不想要',
    Outstock: '缺货',
    Deliverytimetoolong: '配送时间太长',
    other: '其他',
    remarks: '备注',
    Pleaseentercomments: '请输入备注',
    determine: '确定',
    cancel: '取消',
    Allorders: '全部订单',
    Orderdetails: '订单详情',
    Pleaseinput: '请输入',
    Tobepaid: '待支付',
    Tobedelivered: '待发货',
    Tobereceived: '待收货',
    Completed: '已完成',
    Cancelled: '已取消',
    Refundcomplete: '退款完成',
    Applyingrefund: '申请退款中',
    Areyoureceivegoods: '您确定收货吗',
    settlement: '订单结算',
    Consigneeinformation: '收货人信息',
    Changeaddress: '更换地址',
    Selectaddress: '选择地址',
    Paymentmethod: '支付方式',
    priceinformation: '价格信息',
    Totalamountgoods: '商品总金额',
    freight: '运费',
    Totalorderamount: '订单总金额',
    Payimmediately: '立即支付',
    Paymentfailed: '支付失败',
    Pleasereceivingaddress: '请先添加收货地址',
    paid: '已支付',
    unpaid: '未支付',
    postcode: '邮编',
    stateRegion: '省',
    ToPay: '去支付',
    payPalToPay: '使用payPal去支付',
    checkoutToPay: '使用checkout去支付'
  }
}
