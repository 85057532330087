export default {
  login: ['POST', '/login'], // 用户登录
  register: ['POST', '/user/register'], // 用户注册

  loginOut: ['POST', '/loginOut'], // 退出登录
  sendSmsCode: ['GET', '/user/sendSmsCode'], // 发送短信验证码
  resetPassword: ['POST', '/user/resetPassword'], // 忘记密码

  userInfo: ['GET', '/user/getCurrentUser'] // 获取用户信息
}
