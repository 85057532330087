/*
 * @Author: Neko
 * @Date: 2021-04-02 09:20:08
 * @LastEditTime: 2021-04-02 09:20:53
 * @LastEditors: Neko
 */
export default {
  path: '/article',
  name: 'Article',
  redirect: { name: 'ArticlePage' },
  component: () => import('@/view/article/view.vue'),
  meta: {
    name: '文章列表'
  },
  children: [
    {
      path: 'index',
      name: 'ArticlePage',
      component: () => import('@/view/article/index.vue'),
      meta: {
        name: '文章详情'
      }
    }
  ]
}
