/*
 * @Author: Neko
 * @Date: 2021-01-08 09:54:09
 * @LastEditTime: 2021-01-08 09:55:36
 * @LastEditors: Neko
 */
export default {
  path: '/goods',
  name: 'GoodsView',
  component: () => import('@/view/goodsDetails/view.vue'),
  children: [
    {
      path: 'detail',
      name: 'GoodsDetail',
      component: () => import('@/view/goodsDetails/index.vue')
    }
  ]
}
