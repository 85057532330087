/*
 * @Author: Neko
 * @Date: 2021-01-16 10:58:53
 * @LastEditTime: 2021-01-16 13:27:10
 * @LastEditors: Neko
 */
export default {
  getOnceToken: ['POST', '/user/once/createToken'],
  handleBindAuthAction: ['POST', '/user/bindCardNo']
}
