/*
 * @Author: Neko
 * @Date: 2021-01-13 13:33:18
 * @LastEditTime: 2021-10-13 19:26:04
 * @LastEditors: Please set LastEditors
 */
import i18n from '@/language'
import { LANGUAGE_LIST } from '@/utils/constant.js'
import session from '@/utils/session.js'
import { watch } from '@vue/composition-api'
import Vue from 'vue'

const app = session.get('app')

const state = {
  histories: app?.histories || [],
  template: [],
  shopData: {},

  // 获取刷新首页的接口
  refreshModelFunc: function() {},

  redirectQuery: {},

  // 获取页头信息
  headerData: null,

  footerData: null,

  currencyItem: { id: 5 }, // app?.currencyItem || '',
  countryItem: { id: 35 }, // app?.countryItem || '',
  language: app?.language || '',

  countryList: [],
  currencyList: [],
  domain: ''
}

const mutations = {
  SET_HISTORY: (state, word) => {
    if (word.trim()) {
      if (!state.histories.includes(word)) {
        state.histories.splice(0, 0, word)
      }
    }
  },
  SET_DOMAIN: (state, data) => {
    state.domain = data
  },
  SET_SHOP_DATA: (state, data) => {
    // document.title = data.storeName || 'Wharfon SEA'

    state.shopData = data
  },

  SET_HOME_TEMPLATE: (state, template) => {
    state.template = template
  },

  SET_REFRESH_MODEL_FUNC: (state, func) => {
    state.refreshModelFunc = func
  },

  SET_REDIRECT_QUERY: (state, redirect) => {
    state.redirectQuery = redirect
  },

  SET_HEADER_DATA: (state, data) => {
    state.headerData = data
  },

  SET_FOOTER_DATA: (state, data) => {
    state.footerData = data
  },

  SET_COUNTRY: (state, data) => {
    state.countryItem = { id: 35 } // data
  },

  SET_CURRENCY: (state, data) => {
    state.currencyItem = { id: 5 } // data
  },

  SET_COUNTRY_LIST: (state, data) => {
    state.countryList = data
  },

  SET_CURRENCY_LIST: (state, data) => {
    state.currencyList = data
  },

  SET_LANGUAGE: (state, data) => {
    const found = LANGUAGE_LIST.find(current => new RegExp(current.value, 'ig').test(data))

    if (found) {
      i18n.locale = found.value
      state.language = found.value
      Vue.config.lang = found.value
    } else {
      i18n.locale = LANGUAGE_LIST[0].value
      state.language = LANGUAGE_LIST[0].value
      Vue.config.lang = LANGUAGE_LIST[0].value
    }
  }
}

const actions = {
  async GET_HEADER_DATA(store, preload) {
    if (!preload) return

    preload.fetch()

    watch(preload.result, res => {
      const result = Object.entries(res).find(([key, value]) => {
        const found = store.state.template.find(inner => +inner.id === +key && inner.sonType === 3)

        if (found) {
          return value
        }
      })

      if (result) {
        store.commit('SET_HEADER_DATA', result[1][0])
      }
    })
  },

  GET_FOOTER_DATA(store, preload) {
    preload.fetch()

    watch(preload.result, res => {
      const result = Object.entries(res).find(([key, value]) => {
        const found = store.state.template.find(inner => +inner.id === +key && inner.sonType === 5)

        if (found) {
          return value
        }
      })

      if (result) {
        store.commit('SET_FOOTER_DATA', result[1][0])
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
