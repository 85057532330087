/*
 * @Author: Neko
 * @Date: 2021-01-12 11:04:33
 * @LastEditTime: 2021-10-08 16:22:56
 * @LastEditors: Please set LastEditors
 */
export default {
  getHomeData: ['GET', '/microPage/getPageModelList'],

  getHotwordList: ['GET', '/microPage/getKeywordInfo'],
  getAdvertisementList: ['GET', '/microPage/getAdvertInfo'],
  getNavList: ['GET', '/microPage/getNavInfo'],
  getGoodsList: ['GET', '/microPage/getGoodsInfo'], 
  getGoodsGroupList: ['GET', '/microPage/getGoodsGroupInfo'],
  getTitleList: ['GET', '/microPage/getTitleInfo'],
  getNoticeList: ['GET', '/microPage/getNoticeInfo'],

  getMoreList: ['GET', '/microPage/getGoodsGroupInfo/getMore'],

  getTopicPageTitle: ['GET', '/microPage/getPageTemplateById'],
  getComponentData: ['GET', '/microPage/getJumpPage'],
  getStoreListData: ['GET', '/microPage/getStoreInfo'],
  getSeoData: ['GET', '/seoConfiguration/merchantSeo'],
  getInfoByDomain: ['GET', '/microPage/getStoreByDomain'],
  getPolicylist: ['GET', '/store/policy/list'],
  getPolicydetail:['GET','/store/policy/detail']
}
