/*
 * @Author: Neko
 * @Date: 2021-01-13 10:04:17
 * @LastEditTime: 2021-03-18 16:03:08
 * @LastEditors: Neko
 */
export default {
  getCategoryListLevel1: ['GET', '/quickPurchase/getGoodsCategory'],
  getCountryList: ['GET', '/countryCurrency/list'],
  getCategoryList: ['GET', '/goods/storeClassify']
}
