/*
 * @Author: Neko
 * @Date: 2021-03-20 14:53:11
 * @LastEditTime: 2021-03-20 15:46:31
 * @LastEditors: Neko
 */
export default {
  sendEmailCodeAction: ['GET', '/user/sendEmailCode'],
  handleChangeEmailAction: ['POST', '/user/updateEmail'],

  handleSaveNicknameAction: ['POST', '/user/updateNickName'],

  handleSavePasswordAction: ['POST', '/user/resetPassword']
}
