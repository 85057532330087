/*
 * @Author: Neko
 * @Date: 2021-01-04 09:33:26
 * @LastEditTime: 2021-03-24 13:24:15
 * @LastEditors: Neko
 */

import session from '@/utils/session.js'

const state = {
  token: session.get('token') || '',
  userData: session.get('userData') || {},
  userInfo: session.get('userInfo') || {}
}

const getters = {
  isLogin: state => {
    return !!state.token
  }
}

const mutations = {
  SET_TOKEN(state, token) {
    state.token = token
  },
  SAVE_USERDATA(state, data) {
    state.userData = data
  },
  SAVE_USERINFO(state, data) {
    state.userInfo = data
  },
  REMOVE_USERDATA(state) {
    state.userData = {}
    state.userInfo = {}
  },
  REMOVE_TOKEN(state) {
    state.token = ''
  },

  SET_USERNAME(state, { username }) {
    state.userData.username = username
  },

  SET_USER_EMAIL(state, { email }) {
    state.userData.email = email
  }
}

const actions = {
  SetToken({ commit }, token) {
    commit('SET_TOKEN', token)
    session.set('token', token)
  },
  Login({ commit }, data) {
    commit('SAVE_USERDATA', data)
    session.set('userData', data)
  },
  Logout({ commit }) {
    commit('REMOVE_USERDATA')
    commit('REMOVE_TOKEN')
    session.remove('token')
    session.remove('userData')
    session.remove('userInfo')
  },
  GetUserInfo({ commit }, data) {
    commit('SAVE_USERINFO', data)
    session.set('userInfo', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
