/*
 * @Author: Neko
 * @Date: 2021-01-11 09:19:31
 * @LastEditTime: 2021-01-11 09:21:47
 * @LastEditors: Neko
 */
export default {
  path: '/address',
  name: 'Address',
  redirect: { name: 'AddressList' },
  component: () => import('@/view/address/view.vue'),
  meta: {
    name: '个人中心'
  },
  children: [
    {
      path: 'list',
      name: 'AddressList',
      component: () => import('@/view/address/index.vue'),
      meta: {
        name: '我的地址',
        requireAuth: true
      }
    }
  ]
}
