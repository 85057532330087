export const setSeo = (descriptionVal, keywordsVal, verificationFile, title, gaCode) => {
  document.title = title

  const description = document.createElement('meta')
  description.name = 'description'
  description.content = descriptionVal

  const keywords = document.createElement('meta')
  keywords.name = 'keywords'
  keywords.content = keywordsVal

  const googleSiteVerification = document.createElement('meta')
  googleSiteVerification.name = 'google-site-verification'
  googleSiteVerification.content = verificationFile

  const ga = document.createElement('script')
  ga.setAttribute('type', 'text/javascript')
  ga.text = `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js? id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','${gaCode}');
  `

  // const gaNoscript = document.getElementById('ga')
  // const gaIframe = document.createElement('iframe')
  // gaIframe.src = `https://www.googletagmanager.com/ns.html?id=GTM-XXXX`
  // gaIframe.width = 0
  // gaIframe.height = 0
  // gaIframe.style = 'display:none;visibility:hidden'

  document.getElementsByTagName('head')[0].appendChild(description)
  document.getElementsByTagName('head')[0].appendChild(keywords)
  document.getElementsByTagName('head')[0].appendChild(googleSiteVerification)
  document.body.insertBefore(ga, document.body.lastChild)
  // gaNoscript.appendChild(gaIframe)
}

