/*
 * @Author: Neko
 * @Date: 2020-12-31 11:56:08
 * @LastEditTime: 2021-04-12 14:53:40
 * @LastEditors: Neko
 */
import Vue from 'vue'
import CompositionApi from '@vue/composition-api'

import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import { convertNumberFloat } from '@/utils'

import 'normalize.css'
import i18n from './language'

Vue.config.productionTip = false
Vue.prototype.$convertNumberFloat = convertNumberFloat
store.commit('app/SET_LANGUAGE', 'en') // store.state.app.language ?? navigator.language)
// document.title = 'Wharfon SEA'

Vue.use(CompositionApi)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
