/*
 * @Author: Neko
 * @Date: 2021-01-07 13:34:19
 * @LastEditTime: 2021-01-07 16:42:08
 * @LastEditors: Neko
 */
export default {
  path: '/user',
  name: 'User',
  redirect: { name: 'UserLogin' },
  component: () => import('@/view/user/index.vue'),
  children: [{
    path: 'login',
    name: 'UserLogin',
    component: () => import('@/view/user/login.vue')
  }, {
    path: 'register',
    name: 'UserRegister',
    component: () => import('@/view/user/register.vue'),
    meta: {
      type: 'register'
    }
  }, {
    path: 'forgot',
    name: 'UserForgotPassword',
    component: () => import('@/view/user/register.vue'),
    meta: {
      type: 'forgot'
    }
  }]
}
