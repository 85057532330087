<!--
 * @Author: Neko
 * @Date: 2020-12-31 11:56:08
 * @LastEditTime: 2021-04-02 09:13:34
 * @LastEditors: Neko
-->
<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>
<script>
import Vue from 'vue'
import { useRequest } from '@/utils/request.js'
import { getDomainPrefix } from '@/utils'
import { setSeo } from '@/utils/seo'
export default {
  created() {
    const _this = this
    Vue.prototype.$jst = (args) => {
      return this.$t.call(_this, args)
    }

    const { fetch: getSeo } = useRequest('home/getSeoData', {
      data: {
        domainPrefix: getDomainPrefix
      },
      onSuccess: (res) => {
        const data = res.data
        if (data) {
          setSeo(data.description, data.keyWords, data.verificationFile, data.title, data.gaCode)
        }
      }
    })

    getSeo()
  }
}
</script>
<style lang="scss">
</style>
