/*
 * @Author: Neko
 * @Date: 2021-01-11 15:54:23
 * @LastEditTime: 2021-01-11 15:56:43
 * @LastEditors: Neko
 */
export default {
  path: '/collection',
  name: 'Collection',
  redirect: { name: 'CollectionList' },
  component: () => import('@/view/collection/view.vue'),
  meta: {
    name: '个人中心'
  },
  children: [
    {
      path: 'list',
      name: 'CollectionList',
      component: () => import('@/view/collection/index.vue'),
      meta: {
        name: '我的收藏',
        requireAuth: true
      }
    }
  ]
}
