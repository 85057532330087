/*
 * @Author: Lee
 * @Date: 2020-12-31 11:56:08
 * @LastEditTime: 2021-03-25 20:59:27
 * @LastEditors: Neko
 */
import Vue from 'vue'
import '@/style/element-variables.scss'

import {
  Button,
  Icon,
  Carousel,
  CarouselItem,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Image,
  Input,
  InputNumber,
  Row,
  Col,
  Form,
  FormItem,
  Tabs,
  TabPane,
  Table,
  TableColumn,
  Menu,
  Submenu,
  Select,
  Option,
  MenuItem,
  MenuItemGroup,
  Dialog,
  Checkbox,
  Steps,
  Step,
  Pagination,
  Popover
} from 'element-ui'

Vue.use(Button)
Vue.use(Icon)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Image)
Vue.use(InputNumber)
Vue.use(Row)
Vue.use(Col)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Input)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Select)
Vue.use(Option)
Vue.use(Dialog)
Vue.use(Checkbox)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Pagination)
Vue.use(Popover)

