/*
 * @Author: Neko
 * @Date: 2021-01-11 11:30:28
 * @LastEditTime: 2021-01-14 16:21:37
 * @LastEditors: Neko
 */
export default {
  path: '/cart',
  name: 'Cart',
  redirect: { name: 'CartList' },
  component: () => import('@/view/cart/view.vue'),
  children: [
    {
      path: 'list',
      name: 'CartList',
      component: () => import('@/view/cart/index.vue'),
      meta: {
        requireAuth: true
      }
    }
  ]
}
