/*
 * @Author: Neko
 * @Date: 2020-12-31 11:56:08
 * @LastEditTime: 2021-01-13 13:35:33
 * @LastEditors: Neko
 */
import Vue from 'vue'
import Vuex from 'vuex'

import plugins from './plugins.js'

import User from './modules/user'
import App from './modules/app'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user: User,
    app: App
  },

  plugins
})
