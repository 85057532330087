/*
 * @Author: Neko
 * @Date: 2021-01-06 17:16:15
 * @LastEditTime: 2021-01-06 17:16:27
 * @LastEditors: Neko
 */
export default {
  path: '/search',
  name: 'Search',
  component: () => import('@/view/search/index.vue')
}
