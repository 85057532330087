/*
 * @Author: Neko
 * @Date: 2021-01-08 16:07:53
 * @LastEditTime: 2021-01-08 16:10:06
 * @LastEditors: Neko
 */
export default {
  path: '/goodsAll',
  name: 'GoodsAllView',
  redirect: { name: 'GoodsAllList' },
  component: () => import('@/view/goodsAll/view.vue'),
  children: [
    {
      path: 'list',
      name: 'GoodsAllList',
      component: () => import('@/view/goodsAll/index.vue')
    }
  ]
}
