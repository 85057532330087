/*
 * @Author: Neko
 * @Date: 2021-01-07 09:30:49
 * @LastEditTime: 2021-03-20 10:01:02
 * @LastEditors: Neko
 */
export default {
  path: '/profile',
  name: 'Profile',
  redirect: { name: 'OrderList' },
  component: () => import('@/view/profile/index.vue'),
  children: [
    {
      path: 'order',
      name: 'OrderList',
      component: () => import('@/view/profile/order.vue'),
      meta: {
        name: '我的订单',
        requireAuth: true
      }
    }
  ]
}
