/*
 * @Author: Neko
 * @Date: 2021-01-04 09:53:34
 * @LastEditTime: 2021-01-04 09:53:48
 * @Description: localStorage封装
 * @LastEditors: Neko
 */

export default {
  get(key) {
    try {
      return JSON.parse(localStorage.getItem(key))
    } catch (error) {
      return null
    }
  },
  set(key, val) {
    localStorage.setItem(key, JSON.stringify(val))
  },
  remove(key) {
    localStorage.removeItem(key)
  },
  clear() {
    localStorage.clear()
  }
}
