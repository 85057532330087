/*
 * @Author: Neko
 * @Date: 2021-01-13 17:00:23
 * @LastEditTime: 2021-01-16 16:39:25
 * @LastEditors: Neko
 */
export default {
  getGoodsDetail: ['GET', '/goods/goodsDetail'],
  getGoodsFormat: ['GET', '/goods/getGoodsSkuInfo'],

  handleAddCartAction: ['POST', '/cart/add'],
  // handlePurchaseAction: ['POST', '/order/buyFast/generateOrder'],

  handleGoodsCollectAction: ['POST', '/goodsCollect/collect'],
  getGoodsCollectionList: ['GET', '/goodsCollect/list'],
  handleDeleteGoodsCollectionAction: ['POST', '/goodsCollect/cancelCollect']
}
