/*
 * @Author: Neko
 * @Date: 2021-01-08 09:47:04
 * @LastEditTime: 2021-01-11 16:08:52
 * @LastEditors: Neko
 */
export default {
  path: '/order',
  name: 'OrderView',
  component: () => import('@/view/order/index.vue'),
  children: [
    {
      path: 'submit',
      name: 'OrderSubmit',
      component: () => import('@/view/order/submit.vue'),
      meta: {
        requireAuth: true
      }
    },
    {
      path: 'detail',
      name: 'OrderDetail',
      component: () => import('@/view/order/detail.vue'),
      meta: {
        requireAuth: true
      }
    }
  ]
}
